@font-face {
  font-family: customfont;
  src: url('./font/custom.ttf');
}

.ant-modal-content {
    overflow: auto;
    border-radius: 1rem;
  }

* {
  font-family: 'Noto Sans TC', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700;900&display=swap');