.chicken-modal {
    
}

.chicken-modal .ant-modal-body {
    padding: 32px;
}

.chicken-modal .ant-modal {
    width: 700px !important;
}

@media screen and (max-width: 768px) {
    .chicken-modal .ant-modal-body {
        padding: 18px;
    }
    .chicken-modal .ant-modal {
        width: 90% !important;
    }
}