.navbar-2 {
    position: sticky;
    top: 0px;
    display: block;
}

.w-nav {
    background: #dddddd;
    z-index: 1000;
}

.w-nav-menu {
    display: flex;
    align-items: center;
}



.w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
}

.container-2 {
    min-width: 100%;
    margin-right: 0px;
    padding-right: 25px;
    padding-left: 25px;
    background-color: rgba(0, 0, 0, 0.85);
    height: 97px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.w-nav-button {
    position: relative;
    float: right;
    padding: 18px;
    font-size: 24px;
    display: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.nav-link-sticky {
    padding: 5px 5px 10px;
    color: #fff;
}

.w-inline-block {
    max-width: 100%;
    display: inline-block;
}

.whitelistbutton {
    width: 100%;
    padding: 4px 14px;
    border-radius: 20px;
    background-color: #fff;
    font-family: Roboto, sans-serif;
    color: #000;
    font-size: 20px;
}

.whitelistbutton h1 {
    line-height: 44px;
    margin-top: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    margin: 0.67em 0;
}

.main-logo {
    width: 474px;
}

@media screen and (max-width: 991px) {
    .container-2 {
        height: 53px;
    }
    .w-nav-menu {
        display: none;
    }
    .main-logo {
        width: 100%;
    }
}

